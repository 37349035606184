<template>
  <div class="container">

    <FloatingButton icon="fas fa-comment-dots" @click="router.push({name: 'AllRooms'})"/>
    
    <div class="row grey darken-1 marginTop">
        
        <div class="col s12 center orange">
            <h5 class="white-text">Usuarios</h5>
        </div>

        <LateralNav />

        <div class="col s12 l8 grey darken-2 center">

          <p class="white-text left-align">
            Especifique el alias del usuario que desea buscar en el formulario abajo.
          </p>
          
          <form>
          
            <div class="input-field">
              <input type="text" id="user" v-model="user">
              <label for="user">
                <i class="fas fa-search"></i> Buscar Alias del Usuario
              </label>
            </div>

            <br>

            <LargeButton 
              text="Busca"
              icon="fas fa-search"
              color="orange"
              position="left"
              @click="search"
            />

          </form>
        
        </div>

    </div>
  </div>

  <teleport to="body">
    <ReputationModal 
      :isActive="isActive" 
      :user="userToBeChecked" 
      @close="isActive=false"
    />
  </teleport>

</template>

<script>
import LateralNav from '@/components/nav/LateralNav'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import ReputationModal from '@/components/modals/ReputationModal'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import useBoilerplate from '@/composables/useBoilerplate';
import { ref } from '@vue/reactivity'

export default {
    components: { FloatingButton, LargeButton, ReputationModal, LateralNav },
    setup(){
        
        const { router, isActive } = useBoilerplate(false);
        
        const user = ref('');
        const userToBeChecked = ref(null);
        
        const search = async () => {
          userToBeChecked.value = user.value;
          isActive.value = true;
          user.value = '';
        }

        return { user, isActive, search, userToBeChecked, router }
    
    }
}
</script>

<style scoped>
  .blue-text{ cursor: pointer; }
  .l8{
    min-height: 75vh;
  }
</style>